import { BooleanTickCrossIcon } from "components";
import { AppDeleteButton } from "components/Buttons";
import { AppCheckbox } from "components/FormFields";
import { ImageAssets } from "globals/images";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { defaultTo } from "lodash-es";
import { Optional, Selectable } from "models/general";
import { KindergartenClientListing } from "models/kindergartenClient";
import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./KindergartenClientsTableRows.module.scss";
interface ClientsTableRowsProps {
    value: Selectable<KindergartenClientListing>;
    onDelete: () => void;
    canEdit: boolean;
    canDelete: boolean;
    loading: boolean;
    onEditClick: (id: string) => void;
    onCheck: (val: boolean, id: Optional<number>) => void;
}

export const ClientsTableRows: React.FC<ClientsTableRowsProps> = ({
    value,
    onDelete,
    canEdit,
    canDelete,
    loading: refetchLoading,
    onEditClick,
    onCheck,
}) => {
    const { getDateFormatForLocale } = useLocaleHelpers();
    const { t } = useTranslation();

    const onRowClickHandler = (EncodedId: Optional<string>) => {
        if (canEdit && EncodedId) {
            onEditClick(EncodedId);
        }
    };

    return (
        <tr>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
                className={styles.displayId}
            >
                {value.DisplayId}
            </td>
            <td
                style={{ maxWidth: "300px" }}
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span className={styles.textEllipse}>{value.Name}</span>
                </div>
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {t(`common.status.${value.Status}`)}
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {value.Gender ? t(`common.gender.${value.Gender}`) : ""}
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                <span className={styles.textEllipse}>{value.Group}</span>
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {value.CareScopeShortName}
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {value.Birthday.format(getDateFormatForLocale())}
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {value.AgeGroup}
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {value.StartOfCare &&
                    value.StartOfCare.format(getDateFormatForLocale())}
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {value.EndOfContract &&
                    value.EndOfContract.format(getDateFormatForLocale())}
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {t(`common.${value.HasParentRepresentative ? "yes" : "no"}`)}
            </td>
            <td>
                <AppCheckbox
                    checked={defaultTo(value.isChecked, false)}
                    onChange={() => onCheck(!value.isChecked, value.Id)}
                />
            </td>
            <td>
                <BooleanTickCrossIcon value={value.IsSibling} />
            </td>
            <td
                style={{ textAlign: "left" }}
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                <Image
                    className={styles.mobileImage}
                    src={`${
                        value.IsAppLinked
                            ? ImageAssets.common.mobileConnected
                            : ImageAssets.common.mobileNotConnected
                    }`}
                />
            </td>
            <td>
                <AppDeleteButton
                    onClick={() => {
                        onDelete();
                    }}
                    readonly={!canDelete || refetchLoading}
                    name={value.Name}
                    getConfirmation={true}
                />
            </td>
        </tr>
    );
};

export default ClientsTableRows;
