import { AppTable, AppTableSkeleton } from "components/Table";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import useDeleteRow from "hooks/useDeleteRow";
import { Optional, Selectable, TableSort } from "models/general";
import {
    KindergartenClientFilters,
    KindergartenClientFilterSelectOptions,
    KindergartenClientListing,
    KindergartenClientSortColumn,
} from "models/kindergartenClient";
import {
    Kindergarten_Client_BasicData,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import KindergartenClientsTableHeaderRow from "screens/kindergarten/clients/partials/Table/KindergartenClientsTableHeaderRow";
import {
    getKindergartenClientServiceKey,
    KindergartenClientService,
} from "services/kindergarten/KindergartenClientService";
import { KindergartenClientsTableFilter } from "./KindergartenClientsTableFilter";
import { ClientsTableRows } from "./KindergartenClientsTableRows";
export interface KindergartenClientsTableProps {
    value?: Optional<Selectable<KindergartenClientListing>[]>;
    headerFilterOptions: KindergartenClientFilterSelectOptions;
    sort: TableSort<KindergartenClientSortColumn>;
    onSortChange: (sort: TableSort<KindergartenClientSortColumn>) => void;
    loading: boolean;
    onEditClick: (encodedClientId: string) => void;
    onFilterChange: (value: KindergartenClientFilters) => void;
    filterValues: KindergartenClientFilters;
    completeResponse?: KindergartenClientListing[];
    onChangeCompleteResponse?: (list: KindergartenClientListing[]) => void;
    onChange?: (list: Selectable<KindergartenClientListing>[]) => void;
}
const COLUMNS_COUNT = 14;
export const KindergartenClientsTable: React.FC<
    KindergartenClientsTableProps
> = ({
    value: data,
    loading,
    onSortChange,
    onEditClick,
    onFilterChange,
    headerFilterOptions,
    filterValues,
    sort,
    onChangeCompleteResponse,
    completeResponse,
    onChange: onChangeClientList,
}) => {
    const { t } = useTranslation();
    const { checkPermission } = useCheckPermission();
    const { linkProvider } = useRouting();
    const { setDeleteInfo, handleResponse: handleDeleteResponse } =
        useDeleteRow();

    const clientService = new KindergartenClientService(
        linkProvider.kindergarten.clients().api
    );
    const {
        isLoading: deleting,
        mutate: deleteClient,
        data: deleteResponse,
        reset: resetDelete,
        error: deleteError,
    } = useMutation(
        getKindergartenClientServiceKey("delete"),
        async (id: string) => await clientService.delete(id)
    );

    useEffect(() => {
        if (!deleting && (deleteResponse || deleteError)) {
            handleDeleteResponse(
                (id: number) => {
                    if (onChangeCompleteResponse && completeResponse) {
                        onChangeCompleteResponse(
                            completeResponse?.filter((c) => c.Id !== id)
                        );
                    }
                },
                deleteResponse,
                deleteError
            );
            resetDelete();
        }
    }, [
        deleteResponse,
        deleting,
        handleDeleteResponse,
        deleteError,
        completeResponse,
    ]);

    const canDelete = checkPermission(Kindergarten_Client_BasicData, [
        PermissionAccessTypes.DELETE,
    ]);
    const canEdit = checkPermission(Kindergarten_Client_BasicData, [
        PermissionAccessTypes.SHOW,
    ]);

    let allSelected = false;
    if (data && data.length > 0) {
        allSelected = data.filter((x) => !x.isChecked).length == 0;
    }

    return (
        <>
            <AppTable
                id={"scrollableDiv"}
                tableClass={"table-striped"}
                hover={canEdit}
                stickyHeader={true}
            >
                <thead>
                    <KindergartenClientsTableHeaderRow
                        value={sort}
                        allSelected={allSelected}
                        onSelectAll={(val: boolean) => {
                            if (data && onChangeClientList) {
                                onChangeClientList(
                                    data.map((x) => {
                                        return {
                                            ...x,
                                            isChecked: val,
                                        };
                                    })
                                );
                            }
                        }}
                        onChange={(
                            val: TableSort<KindergartenClientSortColumn>
                        ) => onSortChange(val)}
                    />
                </thead>
                <tbody>
                    <KindergartenClientsTableFilter
                        values={filterValues}
                        headerFilterOptions={headerFilterOptions}
                        onFilterChange={onFilterChange}
                        data={data ? data : []} // {completeResponse ? completeResponse : []}  changed complete response with filtered response
                        loading={loading}
                    />
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS_COUNT} />
                    </tr>

                    {data &&
                        data.length > 0 &&
                        data.map((x) => (
                            <ClientsTableRows
                                onEditClick={onEditClick}
                                onDelete={() => {
                                    if (x.EncodedId && canDelete) {
                                        deleteClient(x.EncodedId);
                                        setDeleteInfo({
                                            id: x.EncodedId,
                                            displayName: x.Name,
                                        });
                                    }
                                }}
                                key={x.Id}
                                value={x}
                                canEdit={canEdit}
                                canDelete={canDelete}
                                loading={loading || deleting}
                                onCheck={(
                                    val: boolean,
                                    id: Optional<number>
                                ) => {
                                    id &&
                                        onChangeClientList &&
                                        onChangeClientList(
                                            data.map((oV) => {
                                                if (oV.Id == id) {
                                                    oV.isChecked = val;
                                                    return oV;
                                                } else {
                                                    return oV;
                                                }
                                            })
                                        );
                                }}
                            />
                        ))}

                    {loading && (
                        <AppTableSkeleton
                            rows={data && data.length > 0 ? 2 : 12}
                            columns={COLUMNS_COUNT}
                        />
                    )}

                    {data && data.length == 0 && !loading && (
                        <tr>
                            <td
                                colSpan={COLUMNS_COUNT}
                                style={{ textAlign: "center" }}
                            >
                                {t("common.noDataFound")}
                            </td>
                        </tr>
                    )}
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS_COUNT} />
                    </tr>
                </tbody>
            </AppTable>
        </>
    );
};

export default KindergartenClientsTable;
