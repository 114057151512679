export const KindergartenClientEnTranslations = {
    kindergartenClient: {
        contracts: {
            noContractFound: "No contract found...",
            mealGroup: "Meal Group",
            careScope: "Scope of Care",
            salaryGroup: "Salary Group",
            surcharges: "Surcharges",
            externalContract: "External contract number",
            manualInput: "Manual Input",
            manualInputPlaceholder: "Input",
            externalContractError:
                "External contract number code can't be greater than 20 characters.",
            total: "Total",
        },
        additionalInfo: {
            allChilds: "All Childs in this Family",
            noSiblingSelected: "No Siblings Selected",
            notes: "Notes",
            title: "Additional Info",
        },
        app: "App",
        isSibling: "Is Sibling",
        basicInfo: {
            ageGroup: "Age Group",
            nationalityRaw: {
                name: "Nationality (Received)",
            },
            birthday: {
                missing: "Birthday is required",
                name: "Birthday",
            },
            careBeginning: "Care Beginning",
            customerNumber: "Customer Number",
            endOfContract: "End of Contract",
            firstName: {
                missing: "First name is required",
                name: "First Name",
                placeholder: "First Name",
            },
            lastName: {
                missing: "Last name is required",
                name: "Last Name",
                placeholder: "Last Name",
            },
            provision: "Provision",
            schoolStart: "School Start",
            shorthandSymbol: "Shorthand Symbol",
            status: "Status",
            title: "Base Data",
        },
        birthday: "Birthday",
        careBeginning: "Care Beginning",
        careHoursTitle: "Care Hours",
        careHours: "Care Hours",
        contactInfo: {
            city: "City",
            connectedToApp: "Connected To App",
            contactAlreadyExist:
                'This email "{{email}}" is already used in this client' +
                "'s contacts",
            email: {
                invalid: "Email is not valid",
                title: "Email",
            },
            firstName: {
                missing: "First Name is required",
                name: "First Name",
            },
            lastName: {
                missing: "Last Name is required",
                name: "Last Name",
            },
            homeNumber: "Home Number",
            mobileNumber: "Mobile Number",
            parentRepresentative: "Parent Representative",
            postalCode: "Postal Code",
            privateNumber: "Private Number",
            street: "Street",
            title: "{{count}}. Custodian",
            workPhoneNumber: "Work Phone Number",
        },
        endOfContract: "End of Contract",
        fullName: "Full Name",
        gender: "Gender",
        group: "Group",
        hasRepresentative: "Representative",
        id: "ID",
        newContract: "New Contract",
        oldLastName: "Old Last Name: {{name}}",
        registrationsTitle: "Clients Registrations",
        status: "Status",
        surNameDialog: {
            surName: "Surname",
            title: "Add New Surname",
        },
        tabs: {
            accessControl: "Access Control",
            app: "App",
            bankData: "Bank Data",
            basicData: "Base Data",
            careGroups: "Care Groups",
            diseases: "Diseases",
            documents: "Documents",
            authorizedCollector: "Authorized Collector",
            contracts: "Contracts",
            medicalData: "Medical Data",
            vacations: "Vacations",
        },
        title: "Clients",
        totalClients: "Total Clients:",
        registration: {
            progressStatus: {
                name: "Progress Status",
                Pending: "Pending",
                InProgress: "In-Progress",
                InviteSent: "Invitation Sent",
                ContractSent: "Contract Sent",
                Declined: "Declined",
            },
            source: "Source",
            totalRecords: "Total Records:",
            tableArrangedPlace: "Place Confirmation",
            title: "Client Registration",
            scopeOfCare: {
                name: "Scope of Care",
                update: "Update Scope of Care",
                missing: "Scope of care is required",
            },
            year: "Year",
            careStart: "Care Start",
            isSibling: "Is Sibling",
            createdAt: "Created At",
            arrangedPlace: "Place confirmation has already been given",
            hasNoCareStart: "Has no care start",
            hasNoBirthday: "Has no birthday",
            ageGroup: { name: "Age Group", missing: "Age group is required" },
            migrationBackground: "Migration Background",
            placeChange: "Place Change",
            nationality: {
                name: "Nationality",
                missing: "Nationality is required",
            },
            parentType: {
                Father: "Father",
                Mother: "Mother",
            },
            status: {
                name: "Status",
                missing: "Status is required",
            },
        },
    },
};

export default KindergartenClientEnTranslations;
