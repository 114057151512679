import { AppSelectOld } from "components/AppSelect";
import {
    AppInputField,
    AppMonthYearPicker,
    AppNumberInput,
    MonthYearSplittedDropdowns,
} from "components/FormFields";
import { appTableFilterSelectStyles } from "components/Table/AppTable";
import { FORMAT_INTEGER } from "globals/constants";
import { getUniqueList } from "globals/helpers/generalHelper";
import { defaultTo, isNil, uniq } from "lodash";
import { BoolToYesNo, Gender, SelectItem } from "models/general";
import {
    KindergartenClientFilters,
    KindergartenClientFilterSelectOptions,
    KindergartenClientListing,
    KindergartenClientStatus,
} from "models/kindergartenClient";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface KindergartenClientsTableFilterProps {
    values: KindergartenClientFilters;
    data: KindergartenClientListing[];
    headerFilterOptions: KindergartenClientFilterSelectOptions;
    onFilterChange: (values: KindergartenClientFilters) => void;
    loading: boolean;
}
export const KindergartenClientsTableFilter: React.FC<
    KindergartenClientsTableFilterProps
> = ({ onFilterChange, values, data, loading, headerFilterOptions }) => {
    const { t } = useTranslation();
    const allOption: SelectItem = {
        Text: t("common.all"),
        Value: null,
    };

    const { ageGroupOptions, birthdayYears, careEndYears, careStartYears } =
        useMemo(() => {
            const ageGroups = uniq(data.map((x) => x.AgeGroup));
            const birthdayYears = uniq(data.map((x) => x.BirthdayYear));
            const careStartYears = uniq(data.map((x) => x.CareStartYear));
            const careEndYears = uniq(data.map((x) => x.CareEndYear));

            return {
                ageGroupOptions: [
                    allOption,
                    ...(headerFilterOptions.AgeGroups
                        ? headerFilterOptions.AgeGroups.filter((x) =>
                              ageGroups.includes(parseInt(x.Value as string))
                          )
                        : []),
                ],
                birthdayYears: headerFilterOptions.BirthdayYears.filter((x) =>
                    birthdayYears.includes(parseInt(x.Value as string))
                ),
                careEndYears: headerFilterOptions.CareEndYears.filter((x) =>
                    careEndYears.includes(parseInt(x.Value as string))
                ),
                careStartYears: headerFilterOptions.CareStartYears.filter((x) =>
                    careStartYears.includes(parseInt(x.Value as string))
                ),
            };
        }, [headerFilterOptions, data]);

    const { careScopeOptions, groupsOptions } = useMemo(() => {
        return {
            // ageGroupOptions: [
            //     allOption,
            //     ...(data
            //         ? [
            //               ...data.map((x) => {
            //                   return { Text: x.AgeGroup, Value: x.AgeGroup };
            //               }),
            //           ]
            //         : []),
            // ] as SelectItem[],
            groupsOptions: [
                allOption,
                ...(data
                    ? [
                          ...data
                              .filter((x) => Boolean(x.GroupId))
                              .map((x) => {
                                  return { Text: x.Group, Value: x.GroupId };
                              }),
                      ]
                    : []),
            ] as SelectItem[],
            careScopeOptions: [
                allOption,
                ...(data
                    ? [
                          ...data
                              .filter((x) => !isNil(x.CareScopeUuid))
                              .map((x) => {
                                  return {
                                      Text: x.CareScopeShortName,
                                      Value: x.CareScopeUuid,
                                  };
                              }),
                      ]
                    : []),
            ],
        };
    }, [data, allOption]);

    const { statusOptions, genderOptions, booleanOptions } = useMemo(() => {
        return {
            statusOptions: [
                allOption,
                ...[
                    ...Object.values(KindergartenClientStatus).map((x) => {
                        return {
                            Text: t(`common.status.${x.toString()}`),
                            Value: x.toString(),
                        } as SelectItem;
                    }),
                ],
            ],
            genderOptions: [
                allOption,
                ...Object.values(Gender).map((x) => {
                    return {
                        Text: t(`common.gender.${x.toString()}`),
                        Value: x,
                    } as SelectItem;
                }),
            ],
            booleanOptions: [
                allOption,
                ...Object.values(BoolToYesNo)
                    .filter((x) => typeof x === "number")
                    .map((x) => {
                        return {
                            Text: t(`common.${x === 1 ? "yes" : "no"}`),
                            Value: x.toString(),
                        } as SelectItem;
                    }),
            ],
        };
    }, [t, allOption]);

    return (
        <tr className="filters-row">
            <td className="default-padding">
                <AppNumberInput
                    showClearButton={true}
                    showIcon={false}
                    showLabel={false}
                    formatTheInitialValue={false}
                    showEmptyField={true}
                    showPlaceHolder={false}
                    format={FORMAT_INTEGER}
                    value={values?.DisplayId}
                    onChange={(e) => {
                        if (onFilterChange) {
                            if (e && e > 0) {
                                onFilterChange({ ...values, DisplayId: e });
                            } else {
                                onFilterChange({ ...values, DisplayId: null });
                            }
                        }
                    }}
                />
            </td>
            <td>
                <AppInputField
                    showClearButton={true}
                    value={values.Name as string}
                    onValueChange={(val) => {
                        if (onFilterChange) {
                            onFilterChange({ ...values, Name: val });
                        }
                    }}
                />
            </td>
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(values.Status)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    options={statusOptions}
                    styles={appTableFilterSelectStyles}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    value={
                        values.Status == null
                            ? allOption
                            : statusOptions.find(
                                  (x) => x.Value === values.Status
                              )
                    }
                    onChange={(e: SelectItem) => {
                        if (e) {
                            onFilterChange({
                                ...values,
                                Status: defaultTo(
                                    e.Value as KindergartenClientStatus,
                                    null
                                ),
                            });
                        }
                    }}
                />
            </td>
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(values.Gender)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    options={genderOptions}
                    styles={appTableFilterSelectStyles}
                    value={genderOptions.find((x) => x.Value == values.Gender)}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    onChange={(e: SelectItem) =>
                        e &&
                        onFilterChange &&
                        onFilterChange({
                            ...values,
                            Gender: e.Value as string,
                        })
                    }
                />
            </td>
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(values.GroupId)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    loading={loading}
                    styles={appTableFilterSelectStyles}
                    options={getUniqueList(groupsOptions, "Value")}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    value={groupsOptions.find(
                        (x) => x.Value === values.GroupId
                    )}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) =>
                        e &&
                        onFilterChange({
                            ...values,
                            GroupId: e.Value as number,
                        })
                    }
                />
            </td>
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(values.CareScopeUuid)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    loading={loading}
                    styles={appTableFilterSelectStyles}
                    options={getUniqueList(careScopeOptions, "Value")}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) => {
                        onFilterChange({
                            ...values,
                            CareScopeUuid: e ? (e.Value as string) : null,
                            CareScopeShortName:
                                e && e.Value ? (e.Text as string) : undefined,
                        });
                    }}
                    value={
                        values.CareScopeShortName == null
                            ? allOption
                            : careScopeOptions.find(
                                  (x) => x.Text === values.CareScopeShortName
                              )
                    }
                />
            </td>
            <td>
                <MonthYearSplittedDropdowns
                    value={{
                        month: values.BirthdayMonth,
                        year: values.BirthdayYear,
                    }}
                    yearOptions={birthdayYears}
                    onChange={(v: { month?: number; year?: number }) => {
                        onFilterChange({
                            ...values,
                            BirthdayMonth: v.month,
                            BirthdayYear: v.year,
                        });
                    }}
                />
            </td>
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(values.AgeGroup)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    loading={loading}
                    styles={appTableFilterSelectStyles}
                    options={getUniqueList(ageGroupOptions, "Value")}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    value={
                        values.AgeGroup
                            ? ageGroupOptions.find(
                                  (x) => x.Value === values.AgeGroup
                              )
                            : allOption
                    }
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) =>
                        e &&
                        onFilterChange({
                            ...values,
                            AgeGroup: e.Value as number,
                        })
                    }
                />
            </td>
            <td>
                <MonthYearSplittedDropdowns
                    value={{
                        month: values.CareStartMonth,
                        year: values.CareStartYear,
                    }}
                    yearOptions={careStartYears}
                    onChange={(v: { month?: number; year?: number }) => {
                        onFilterChange({
                            ...values,
                            CareStartMonth: v.month,
                            CareStartYear: v.year,
                        });
                    }}
                />
            </td>
            <td>
                <MonthYearSplittedDropdowns
                    value={{
                        month: values.CareEndMonth,
                        year: values.CareEndYear,
                    }}
                    yearOptions={careEndYears}
                    onChange={(v: { month?: number; year?: number }) => {
                        onFilterChange({
                            ...values,
                            CareEndMonth: v.month,
                            CareEndYear: v.year,
                        });
                    }}
                />
            </td>
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(
                        values.HasParentRepresentative
                    )}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    styles={appTableFilterSelectStyles}
                    value={
                        values.HasParentRepresentative == null
                            ? allOption
                            : booleanOptions
                                  .filter((x) => x.Value != null)
                                  .find(
                                      (x) =>
                                          Boolean(Number(x.Value)) ===
                                          values.HasParentRepresentative
                                  )
                    }
                    options={booleanOptions}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) => {
                        if (e) {
                            onFilterChange({
                                ...values,
                                HasParentRepresentative:
                                    e.Value != null
                                        ? Boolean(Number(e.Value))
                                        : null,
                            });
                        }
                    }}
                />
            </td>
            <td />
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(values.IsSibling)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    styles={appTableFilterSelectStyles}
                    value={
                        values.IsSibling == null
                            ? allOption
                            : booleanOptions
                                  .filter((x) => x.Value != null)
                                  .find(
                                      (x) =>
                                          Boolean(Number(x.Value)) ===
                                          values.IsSibling
                                  )
                    }
                    options={booleanOptions}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) => {
                        if (e) {
                            onFilterChange({
                                ...values,
                                IsSibling:
                                    e.Value != null
                                        ? Boolean(Number(e.Value))
                                        : null,
                            });
                        }
                    }}
                />
            </td>
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(values.IsAppLinked)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    styles={appTableFilterSelectStyles}
                    value={
                        values.IsAppLinked == null
                            ? allOption
                            : booleanOptions
                                  .filter((x) => x.Value != null)
                                  .find(
                                      (x) =>
                                          Boolean(Number(x.Value)) ===
                                          values.IsAppLinked
                                  )
                    }
                    options={booleanOptions}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) => {
                        if (e) {
                            onFilterChange({
                                ...values,
                                IsAppLinked:
                                    e.Value != null
                                        ? Boolean(Number(e.Value))
                                        : null,
                            });
                        }
                    }}
                />
            </td>
            <td />
        </tr>
    );
};

export default KindergartenClientsTableFilter;
