import { AppCheckbox } from "components/FormFields";
import { AppTableSortColumnIcons } from "components/Table";
import styles from "./KindergartenClientsTable.module.scss";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { t } from "i18next";
import { defaultTo } from "lodash";
import { TableSort } from "models/general";
import { KindergartenClientSortColumn } from "models/kindergartenClient";
import React from "react";

export interface KindergartenClientsTableHeaderRowProps {
    value: TableSort<KindergartenClientSortColumn>;
    allSelected: boolean;
    onSelectAll: (val: boolean) => void;
    onChange: (val: TableSort<KindergartenClientSortColumn>) => void;
}
export const KindergartenClientsTableHeaderRow: React.FC<
    KindergartenClientsTableHeaderRowProps
> = ({ onChange, allSelected, onSelectAll, value }) => {
    return (
        <tr>
            <AppTableSortColumnIcons
                text={t("kindergartenClient.id")}
                className={styles.displayId}
                sortColumn={KindergartenClientSortColumn.DisplayId}
                onClick={(key, order) =>
                    onChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    value.SortColumn == KindergartenClientSortColumn.DisplayId
                        ? value.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                text={t("kindergartenClient.fullName")}
                style={getFixedCssWidths(300)}
                sortColumn={KindergartenClientSortColumn.Name}
                onClick={(key, order) =>
                    onChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    value.SortColumn == KindergartenClientSortColumn.Name
                        ? value.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(170)}
                text={t("kindergartenClient.status")}
                sortColumn={KindergartenClientSortColumn.Status}
                onClick={(key, order) =>
                    onChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    value.SortColumn == KindergartenClientSortColumn.Status
                        ? value.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                text={t("kindergartenClient.gender")}
                style={getFixedCssWidths(150)}
                sortColumn={KindergartenClientSortColumn.Gender}
                className={styles.genderTitle}
                onClick={(key, order) =>
                    onChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    value.SortColumn == KindergartenClientSortColumn.Gender
                        ? value.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(200)}
                text={t("kindergartenClient.group")}
                sortColumn={KindergartenClientSortColumn.Group}
                onClick={(key, order) =>
                    onChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    value.SortColumn == KindergartenClientSortColumn.Group
                        ? value.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(170)}
                text={t("kindergartenClient.careHoursTitle")}
                sortColumn={KindergartenClientSortColumn.CareScope}
                onClick={(key, order) =>
                    onChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    value.SortColumn == KindergartenClientSortColumn.CareScope
                        ? value.SortOrder
                        : null
                }
                className={styles.careHours}
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(200)}
                text={t("kindergartenClient.birthday")}
                sortColumn={KindergartenClientSortColumn.Birthday}
                onClick={(key, order) =>
                    onChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    value.SortColumn == KindergartenClientSortColumn.Birthday
                        ? value.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(140)}
                text={t("kindergartenClient.basicInfo.ageGroup")}
                sortColumn={KindergartenClientSortColumn.AgeGroup}
                onClick={(key, order) =>
                    onChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    value.SortColumn == KindergartenClientSortColumn.AgeGroup
                        ? value.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(200)}
                text={t("kindergartenClient.careBeginning")}
                sortColumn={KindergartenClientSortColumn.CareBeginning}
                onClick={(key, order) =>
                    onChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    value.SortColumn ==
                    KindergartenClientSortColumn.CareBeginning
                        ? value.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(200)}
                text={t("kindergartenClient.endOfContract")}
                sortColumn={KindergartenClientSortColumn.EndOfContract}
                onClick={(key, order) =>
                    onChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    value.SortColumn ==
                    KindergartenClientSortColumn.EndOfContract
                        ? value.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(170)}
                text={t("kindergartenClient.hasRepresentative")}
                sortColumn={KindergartenClientSortColumn.HasRepresentative}
                onClick={(key, order) =>
                    onChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    value.SortColumn ==
                    KindergartenClientSortColumn.HasRepresentative
                        ? value.SortOrder
                        : null
                }
            />
            <th style={getFixedCssWidths(50, false)}>
                <AppCheckbox
                    checked={defaultTo(allSelected, false)}
                    onChange={(e) => {
                        onSelectAll(e.target.checked);
                    }}
                />
            </th>
            <AppTableSortColumnIcons
                style={getFixedCssWidths(200)}
                text={t("kindergartenClient.isSibling")}
                sortColumn={KindergartenClientSortColumn.IsSibling}
                onClick={(key, order) =>
                    onChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    value.SortColumn == KindergartenClientSortColumn.IsSibling
                        ? value.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(110)}
                text={t("kindergartenClient.app")}
                sortColumn={KindergartenClientSortColumn.App}
                onClick={(key, order) =>
                    onChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    value.SortColumn == KindergartenClientSortColumn.App
                        ? value.SortOrder
                        : null
                }
            />
            <th style={getFixedCssWidths(70.5, false)} />
        </tr>
    );
};

export default KindergartenClientsTableHeaderRow;
