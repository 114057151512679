export enum KindergartenClientContactType {
    TYPE_PRIMARY = "Primary",
    TYPE_SECONDARY = "Secondary",
}

export enum KindergartenClientStatus {
    OnAdvance = "OnAdvance",
    Active = "Active",
    Expired = "Expired",
}
export enum KindergartenClientSortColumn {
    DisplayId = 0,
    Name = 1,
    Gender = 2,
    Group = 3,
    Birthday = 4,
    CareBeginning = 5,
    EndOfContract = 6,
    Status = 7,
    App = 8,
    CareScope = 9,
    HasRepresentative = 10,
    IsSibling = 11,
    AgeGroup = 12,
}
