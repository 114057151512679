import { ISO8601_DATE_FORMAT } from "globals/constants";
import { Gender, Optional } from "models/general";
import moment from "moment";
import { Moment } from "moment-timezone";
import { KindergartenClientSortColumn, KindergartenClientStatus } from "./enum";
import {
    ContactInfo,
    KindergartenClient,
    KindergartenClientContactInfo,
    KindergartenClientFilters,
    KindergartenClientListing,
} from "./model";

export const getClientStateEnumByNumberValue = (
    num: number
): KindergartenClientStatus => {
    switch (num) {
        case 0:
            return KindergartenClientStatus.OnAdvance;
        case 1:
            return KindergartenClientStatus.Active;
        case 2:
            return KindergartenClientStatus.Expired;
        default:
            return KindergartenClientStatus.Expired;
    }
};
export const getKindergartenClientSortColumnKeyFromEnum = (
    clientsSortColumn: KindergartenClientSortColumn | undefined
): keyof KindergartenClientListing => {
    switch (clientsSortColumn) {
        case KindergartenClientSortColumn.DisplayId:
            return "DisplayId";
        case KindergartenClientSortColumn.Name:
            return "Name";
        case KindergartenClientSortColumn.Gender:
            return "Gender";
        case KindergartenClientSortColumn.Group:
            return "Group";
        case KindergartenClientSortColumn.Birthday:
            return "Birthday";
        case KindergartenClientSortColumn.CareBeginning:
            return "StartOfCare";
        case KindergartenClientSortColumn.EndOfContract:
            return "EndOfContract";
        case KindergartenClientSortColumn.Status:
            return "Status";
        case KindergartenClientSortColumn.App:
            return "IsAppLinked";
        case KindergartenClientSortColumn.IsSibling:
            return "IsSibling";
        case KindergartenClientSortColumn.CareScope:
            return "CareScopeShortName";
        case KindergartenClientSortColumn.HasRepresentative:
            return "HasParentRepresentative";
        case KindergartenClientSortColumn.AgeGroup:
            return "AgeGroup";
        default:
            return "DisplayId";
    }
};

export const getKindergartenClientsFilterDefaultValue =
    (): KindergartenClientFilters => {
        return {
            DisplayId: null,
            IsAppLinked: null,
            Name: null,
            GroupId: null,
            CareScopeUuid: null,
            Gender: null,
            Status: KindergartenClientStatus.Active,
        };
    };

export const getKindergartenClientBasicInfoInitialValues =
    (): KindergartenClient => {
        return {
            Id: null,
            EncodedId: null,
            DisplayId: null,
            AgeGroup: null,
            Birthday: null,
            CareBeginning: null,
            CustomerNumber: null,
            SchoolStart: null,
            EndOfCOntract: null,
            KindergartenId: null,
            Gender: Gender.Male,
            FirstName: "",
            LastName: "",
            CanLinkApp: false,
            Notes: "",
            Provision: false,
            ShorthandSymbol: "",
            Status: KindergartenClientStatus.Expired,
            Name: "",
            NewLastName: "",
            Contacts: [],
            Siblings: [],
        };
    };

export const getClientContactInitialValues = (): ContactInfo => {
    return {
        City: "",
        ConnectedToApp: false,
        CountryCode: "DE",
        Email: "",
        FirstName: "",
        HouseNumber: "",
        LastName: "",
        MobileNumber: "",
        PostCode: "",
        PrivatePhone: "",
        StreetAddress: "",
        WorkPhone: "",
        State: "",
        Gender: Gender.Male,
        NewLastName: "",
        Id: null,
    };
};

export const getKindergartenClientContactInitialValues = (
    clientId: Optional<number>
): KindergartenClientContactInfo => {
    return {
        ClientId: clientId,
        IsParentRepresentative: false,
        IsPrimaryContact: false,
        ...getClientContactInitialValues(),
    };
};

export function getKindergartenClientStatus(
    contractStart: Moment,
    endOfContract?: Moment
) {
    const dateNow = moment();
    if (
        dateNow >= contractStart &&
        (!endOfContract || dateNow <= endOfContract)
    ) {
        return KindergartenClientStatus.Active;
    } else if (dateNow < contractStart) {
        return KindergartenClientStatus.OnAdvance;
    }
    return KindergartenClientStatus.Expired;
}
export function getKindergartenClientAgeGroupYear(
    birthday: Moment,
    deadline?: Optional<Moment> | string
): number {
    let ageGroup = birthday.year();
    if (typeof deadline == "string") {
        deadline = moment(deadline, ISO8601_DATE_FORMAT);
    }
    if (deadline) {
        const temp = deadline.clone().year(ageGroup);
        if (birthday >= temp) {
            ageGroup++;
        }
    }
    return ageGroup;
}

export function getKindergartenClientStartSchool(
    ageGroup: number,
    startOfSchool?: Moment
): Moment {
    const startOfSchoolMoment = moment(startOfSchool, ISO8601_DATE_FORMAT);
    const yearOfSchoolStart = ageGroup + 6;
    const req = startOfSchoolMoment.year(yearOfSchoolStart);
    return req.add(-1, "day").clone();
}
